import React, { Component } from 'react';
import BigNumber from '../../lib/bignumber';
import { IonRow, IonCol, IonIcon } from '@ionic/react';
import Basket from '../../lib/basket';
import { NormalText, SmallText, StrongText, Subtitle } from '../common';
import { withTranslation } from '../../lib/translate';
import { isDefined, isEmptyObject } from '../../lib/utils';
import './index.css';
import { cardOutline } from 'ionicons/icons';

class AmountPaid extends Component {
	determineOrderStatus = (order) => {
		const status = order.status.toLowerCase();

		switch (status) {
			case 'paid':
				return 'Completed';
			case 'failed':
			case 'unpaid':
			case 'declined':
				return 'Failed';
			case 'refunded':
				return 'Refunded';
			case 'cancel':
				return 'Cancel';
			default:
				return 'Processing';
		}
	};

	render() {
		const { __, order, cards } = this.props;
		let amount_paid = isDefined(order.amount_paid) ? order.amount_paid : 0;
		if (amount_paid > 0) {
			// amount_paid were represents payed amount in cents
			amount_paid = new BigNumber(amount_paid).div(100).toNumber();
		}
		let label = 'Card';
		let labelNumber = null
		if ((order.payment_token && cards && cards.length > 0) || !isEmptyObject(order.payment_info)) {
			const card = !isEmptyObject(order.payment_info) ? order.payment_info.card : cards.find((c) => c.id === order.payment_token);
			if (card) {
				const { brand, last4, is_error, message, type } = card;
				if (is_error) {
					label = message
				} else {
					const labels = {
						'apple_pay': `Apple Pay (${brand})`,
						'google_pay': `Google Pay (${brand})`,
						'card': `${brand} card`
					}
					label = labels[type];
					labelNumber = `**** **** **** ${last4}`
				}
			}
		}
		return (
			<>

				{order && order.payment_token === 'Pay on collection' && order.status !== 'PAID' ? (
					<>
						<StrongText className="text-to-update">{__('Payment details')}</StrongText>

						<div className="box-content">

							<div className="ion-color-danger text-box">
								<SmallText color="red"> {__('PAYMENT DUE ON COLLECTION')}</SmallText>
							</div>
						</div>
					</>
				) :
					(

						<>
							{order.status.toLowerCase() == 'new' ? <>
								<div className="no-data-history" >
									<NormalText>{__('Payment card details will be displayed once the order has been paid.')}</NormalText>
								</div>

							</> :
								<>
									{order.amount_paid > 0 &&
										<>
											<StrongText className="text-to-update">{__('Payment details')}</StrongText>
											<div className='box-content'>
												<IonRow className="no-border">
													<IonCol class='paddLR '>
														<div className="card-icon-wrapper">
															<IonIcon icon={cardOutline}></IonIcon>
														</div>
													</IonCol>
													<IonCol className="paddLR grow">
														<NormalText className="card-label block primary-color">{__(label)}</NormalText>
														{labelNumber && <NormalText className="card-label block primary-color">{labelNumber}</NormalText>}

													</IonCol>

												</IonRow>
												<IonRow>
													<IonCol className='paddLR'>
														<div className="order-paid-status">{__(this.determineOrderStatus(order))}</div>
													</IonCol>
												</IonRow>
												{order && order.process_message ? (
													<IonRow className="ion-color-danger">
														<IonCol className="paddLR ion-color-danger">
															<SmallText color="red">*** {__(order.process_message)} ***</SmallText>
														</IonCol>
													</IonRow>

												) : null}
											</div>
										</>

									}
								</>
							}
						</>
					)}
			</>
		);
	}
}

export default withTranslation(AmountPaid);
